<template>
  <Modal class="news-visor default" ref="modal" :settings="{ opened: true, outClose: true }" @close="newsClose" @open="newsOpen">
    <div class="news-visor-title" slot="header">
      <p class="news-visor-icon">
        <iconic name="myspace" />
      </p>
    </div>
    <div class="news-visor-options" slot="header-right">
      <a class="button edit-button" @click="newsMenu(news, $event)" v-if="isAdminNews">
        <iconic name="ellipsis-v" />
      </a>
    </div>
    <div class="news-visor-content" v-if="news && news._id">
      <NewsMediaVisor :news="news" />
      <div class="news-visor-likes">
        <NewsCardSocial :news="data" />
        <LikesInfo :likes="data.likes" :postId="data._id" :news="data" endpoint="/news/likes" class="likesInfo" />
      </div>
      <div class="news-visor-title __content">{{ news.titleNews || "Sin titulo" }}</div>
      <div class="news-visor-subtitle">{{ news.subtitleNews || "" }}</div>
      <div class="news-visor-body" v-html="news.textNews"></div>
      <div class="news-visor-comments">
        <Spacer num="2" />
        <Comments :newId="news._id" :closeModal="newsClose" />
      </div>
    </div>
    <div class="news-visor-error" v-else-if="error">
      <ErrorCard :message="error" />
    </div>
    <div class="news-visor-loading" v-else>
      <iconic name="dots_loading" />
    </div>
  </Modal>
</template>

<script>
  import "./NewsVisor.scss";
  import NewsMixing from "./NewsMixing";
  export default {
    mixins: [NewsMixing],
    components: {
      LikesInfo: () => import("@/components/likes/LikeInfo.vue"),
      NewsCardSocial: () => import("./NewsCardSocial.vue"),
      NewsMediaVisor: () => import("./NewsMediaVisor.vue"),
      Comments: () => import("@/components/news/comments/Comments.vue"),
    },
    data: function() {
      return {
        error: null,
      };
    },
    methods: {
      newsClose: function() {
        this.$store.state.news.NewsVisor = null;
        this.$router.push({ query: { news: undefined } }).catch(() => {});
      },
      newsLoaded: function(data) {
        if (!data.items || !data.items.length) {
          this.error = data.message || "No se encontró la noticia";
        } else {
          this.$store.state.news.NewsVisor = data.items[0];
        }
      },
      newsOpen: function() {
        const { news } = this.$route.query;
        if (news) {
          this.readNew(news);
        }
      },
      async readNew(newId) {
        try {
          const host = `news/reader?newId=${newId}`;
          const { error } = await this.$api.post(`${host}`);

          if (error) {
            throw error;
          }
        } catch (error) {
          this.$Swal.fire({ title: "Error", text: error });
          this.newsClose();
        }
      },
    },
    computed: {
      news: function() {
        return this.$store.state.news.NewsVisor || {};
      },
      data: function() {
        let id = this.$route.query.news;
        let news = [...this.$store.state.news.news, ...this.$store.state.news.others.news, ...this.$store.state.news.featured.news, ...this.$store.state.news.search.news];
        return news.find((element) => element._id === id);
      },
    },
    async beforeMount() {
      setTimeout(() => {
        if (this.$route.query.comments) {
          const element = document.querySelector(".modal-card");
          element.scrollTop = element?.scrollHeight * 50;
          const elemetComentario = document.querySelector(".comment-input");
          elemetComentario.focus();
        }
      }, 500);
    },
  };
</script>
<style lang="scss">
  .news-visor-subtitle {
    padding-left: 30px;
    font-size: 120%;
    font-family: $sec_font;
    margin: 0 0 $mpadding 0;
  }
  .news-visor-icon {
    font-size: 120px;
    margin-top: 28px;
  }
  .news-visor-likes {
    display: flex;
    margin: 0px 25px 0 25px;
    padding: 0px 0;
    border-bottom: 1px solid #d8d8d8;
    .comment {
      margin-right: 20px;
    }
    .likesInfo {
      margin-top: 15px;
      margin-left: 15px;
    }
  }
</style>
